import axios from 'axios'
// import NProgress from 'nprogress'

const authAxios = axios.create({
  baseURL: process.env.VUE_APP_AUTH_API,
  timeout: process.env.VUE_APP_CONNECTION_TIMEOUT
})

// Source: https://scotch.io/tutorials/add-loading-indicators-to-your-vuejs-application

// before a request is made start the nprogress
authAxios.interceptors.request.use(config => {
  // NProgress.start()
  return config
})

// before a response is returned stop nprogress
authAxios.interceptors.response.use(response => {
  // NProgress.done()
  return response
})

// Error
// authAxios.interceptors.response.use(
//   function (response) {
//     NProgress.done()
//     return response
//   },
//   function (error) {
//     NProgress.done()
//     return error
//   }
// )

const dataAxios = axios.create({
  baseURL: process.env.VUE_APP_MAIN_API,
  timeout: process.env.VUE_APP_CONNECTION_TIMEOUT
})

// before a request is made start the nprogress
dataAxios.interceptors.request.use(config => {
  // NProgress.start()
  return config
})

// before a response is returned stop nprogress
dataAxios.interceptors.response.use((response) => {
  // NProgress.done()
  return response
})

// Error
// dataAxios.interceptors.response.use(
//   function (response) {
//     NProgress.done()
//     return response
//   },
//   function (error) {
//     NProgress.done()
//     return error
//   }
// )

export { authAxios, dataAxios }

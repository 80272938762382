import LayoutBlank from '@/layout/LayoutBlank'

export default [
  {
    path: '/',
    component: LayoutBlank,
    children: [
      {
        path: 'login',
        component: () => import('@/pages/Login'),
        meta: {
          public: true, // Allow access to guest user.
          onlyWhenLoggedOut: true
        }
      }
    ]
  }
]

/** VUEX module for Home **/

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    connected: true
  },
  // -----------------------------------------------------------------
  getters: {
    connected: state => {
      return state.connected
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    setConnected: (state, data) => {
      state.connected = data
    }
  },
  // -----------------------------------------------------------------
  actions: {
    'SOCKET_connect' ({ commit }) {
      commit('setConnected', true)
    },
    'SOCKET_disconnect' ({ commit }) {
      commit('setConnected', false)
    },
    async 'SOCKET_NEW_LEAD' ({ dispatch }) {
      await dispatch('LEADS/getLeadsAfter', null, { root: true })
    }

  }
}

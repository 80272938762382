// Best Practices:
// Source: https://docs.vuestorefront.io/guide/vuex/vuex-conventions.html#getters

// Authentication
export const AUTH_START_REQUEST = 'AUTH_START_REQUEST'
export const AUTH_SET_SUCCESS = 'AUTH_SET_SUCCESS'
export const AUTH_SET_ERROR = 'AUTH_SET_ERROR'
export const AUTH_RESET_STATUS = 'AUTH_RESET_STATUS'
export const AUTH_LOGOUT_SUCCESSFUL = 'AUTH_LOGOUT_SUCCESSFUL'
export const AUTH_SET_REFRESH_TASK = 'AUTH_SET_REFRESH_TASK'

// APP
export const APP_UPDATE_NOME_ATIVO_SELECIONADO = 'APP_UPDATE_NOME_ATIVO_SELECIONADO'

// OPC
export const OPC_SET_ATIVOS = 'OPC_SET_ATIVOS'
export const OPC_SET_OPCOES = 'OPC_SET_OPCOES'
export const OPC_START_REQUEST = 'OPC_START_REQUEST'
export const OPC_FINISH_REQUEST = 'OPC_FINISH_REQUEST'

// COT
export const COT_SET_COTACOES_OPCOES_ATIVO = 'COT_SET_COTACOES_OPCOES_ATIVO'
export const COT_SET_COTACOES_OPCAO = 'COT_SET_COTACOES_OPCAO'
export const COT_SET_DATA_ULTIMO_PREGAO = 'COT_SET_DATA_ULTIMO_PREGAO'
export const COT_SET_DATA_PREGAO_SELECIONADA = 'COT_SET_DATA_PREGAO_SELECIONADA'
export const COT_START_REQUEST = 'COT_START_REQUEST'
export const COT_FINISH_REQUEST = 'COT_FINISH_REQUEST'

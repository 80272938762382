import { DataApiService } from '@/services/apis/data.api.service'
import { FILETYPE_URL, DOCUMENTTYPE_URL, FILE_URL, DELETE_FILE_URL, FILE_NAMES_FROM_SUPPLIER } from '@/services/shared/endpoint.types'
import ConnectionError from '@/services/shared/connection.error'

const FileService = {
  FileTypes: async () => {
    try {
      return await DataApiService.get(FILETYPE_URL)
    } catch (error) {
      ConnectionError(error)
    }
  },
  DocumentTypes: async () => {
    try {
      return await DataApiService.get(DOCUMENTTYPE_URL)
    } catch (error) {
      ConnectionError(error)
    }
  },
  DeleteFile: async (uid) => {
    try {
      return await DataApiService.delete(DELETE_FILE_URL.replace(':id', uid))
    } catch (error) {
      ConnectionError(error)
    }
  },
  FileNamesFromSupplier: async (id) => {
    try {
      return await DataApiService.get(FILE_NAMES_FROM_SUPPLIER.replace(':id', id))
    } catch (error) {
      ConnectionError(error)
    }
  },
}

export default FileService

import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import TokenService from '../services/shared/storage.service'
import AuthRoutes from '@/router/auth'
import AdminRoutes from '@/router/dashboard'
import SupplierRoutes from '@/router/suppliers'
import ChangeLogRoute from '@/router/changelog'
import KeepaRoute from '@/router/keepa'
import MagicScreen from '@/router/magicScreen'

import Cart from '@/router/cart'
import Claim from '@/router/claim'
import Order from '@/router/order'
import PrepCenter from '@/router/prepcenter'
import Shipping from '@/router/shipping'

import globals from '@/globals'

Vue.use(Router)
Vue.use(Meta)

const ROUTES = [
  // Default route
  { path: '', redirect: '/dashboard' }
]
  .concat(AuthRoutes)
  .concat(AdminRoutes)
  .concat(SupplierRoutes)
  .concat(ChangeLogRoute)
  .concat(KeepaRoute)
  .concat(MagicScreen)
  .concat(Cart)
  .concat(Claim)
  .concat(Order)
  .concat(PrepCenter)
  .concat(Shipping)
// 404 Not Found page
ROUTES.push({
  path: '*',
  component: () => import('@/pages/NotFound')
})

const router = new Router({
  base: '/',
  mode: 'history',
  routes: ROUTES
})

router.afterEach(() => {
  // On small screens collapse sidenav
  if (
    window.layoutHelpers &&
    window.layoutHelpers.isSmallScreen() &&
    !window.layoutHelpers.isCollapsed()
  ) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  )
  const loggedIn = !!TokenService.getToken()

  if (!isPublic && !loggedIn) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath } // Store the full path to redirect the user to after login
    })
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/dashboard')
  }

  next()
})

export default router

import Layout2 from '@/layout/Layout2'

export default [
  {
    path: '/orders',
    component: Layout2,
    children: [
      {
        path: '',
        component: () => import('@/pages/orders/OrderList'),
        meta: {
          public: false // Allow access to only loggedin user.
        }
      },
      {
        path: 'nfes-x',
        component: () => import('@/pages/suppliers/nfes-x/list/List'),
        meta: {
          public: false
        }
      },
      {
        path: 'nfes',
        component: () => import('@/pages/suppliers/nfes/List'),
        meta: {
          public: false
        }
      },
      {
        path: 'purchases',
        component: () => import('@/pages/suppliers/purchases/List'),
        meta: {
          public: false
        }
      },
    ]
  }
]

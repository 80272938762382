// Sources:
// https://morioh.com/p/4bb19aa8ea3e
// https://github.com/aiyazkhorajia/vue-boilerplate

import { dataAxios } from '@/services/shared/axios.service'
import ApiService from '@/services/apis/api.service'

class Data extends ApiService {
  constructor () {
    super(dataAxios)
    this._401interceptor = null
  }
}

export const DataApiService = new Data()

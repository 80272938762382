export default class GenericError extends Error {
  constructor (errorCode, title, message, errorType, errors = {}) {
    super(message)
    this.name = this.constructor.name
    this.title = title
    this.message = message
    this.errorCode = errorCode
    this.type = errorType || 'error'
    this.errors = errors
  }
}

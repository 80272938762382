import GenericError from '@/services/shared/generic.error'
const ConnectionError = (error) => {
  const { response } = error
  if (response) {
    if (response.status === 403) throw new GenericError(response.status, 'Error', 'Not Authorized')

    const errorMessage = response.data.errors.message || response.data.message || response.data.title ||
                         response.data.responses || response.statusText
    throw new GenericError(response.status, 'Error', errorMessage)
  } else {
    // console.log(error);
    if (error.message.indexOf('timeout') !== -1 || error.message.indexOf('Network Error') !== -1) {
      throw new GenericError('500', 'Error', 'Failed connecting to the server.')
    } else {
      throw new GenericError('500', 'Error', 'Not Identified Error')
    }
  }
}

export default ConnectionError

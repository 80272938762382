import { AuthApiService } from '@/services/apis/auth.api.service'
import { DataApiService } from '@/services/apis/data.api.service'

import TokenService from '@/services/shared/storage.service'
import { AUTH_LOGIN_URL, USER_REFRESH_TOKEN_URL } from '@/services/shared/endpoint.types'

import ConnectionError from '@/services/shared/connection.error'

const setHeaders = () => {
  AuthApiService.setHeader()
  DataApiService.setHeader()
}

const removeHeaders = () => {
  AuthApiService.removeHeader()
  DataApiService.removeHeader()
}

const setInterceptors = () => {
  AuthApiService.mount401Interceptor()
  DataApiService.mount401Interceptor()
}

const removeInterceptors = () => {
  AuthApiService.unmount401Interceptor()
  DataApiService.unmount401Interceptor()
}

const setTokens = (response) => {
  TokenService.saveToken(response.data.token)
  TokenService.saveRefreshToken(response.data.refresh_token || response.data.token)
}

const removeTokens = () => {
  TokenService.removeToken()
  TokenService.removeRefreshToken()
}

const setTokensHeaders = (response) => {
  if (response.data.token) {
    setTokens(response)
    setHeaders()
    setInterceptors()
  }
}

// const setAuthenticationError = (error) => {
//   const { response } = error
//   if (response) {
//     const errorMessage = response.data.message || response.data.title ||
//                          response.data.responses || response.statusText
//     throw new GenericError(response.status, 'Erro', errorMessage)
//   } else {
//     // console.log(error);
//     if (error.message.indexOf('timeout') !== -1 || error.message.indexOf('Network Error') !== -1) {
//       throw new GenericError('500', 'Erro', 'Falha ao conectar com o servidor.')
//     } else {
//       throw new GenericError('500', 'Erro', 'Erro não identificado')
//     }
//   }
// }

const AuthService = {

  login: async (user) => {
    try {
      const response = await AuthApiService.post(AUTH_LOGIN_URL, user)
      setTokensHeaders(response)
      return response.data
    } catch (error) {
      ConnectionError(error)
    }
  },

  logout: () => {
    removeTokens()
    removeHeaders()
    removeInterceptors()
  },

  refreshToken: async () => {
    try {
      const response = await AuthApiService.post(USER_REFRESH_TOKEN_URL)
      setTokensHeaders(response)
      return response.data
    } catch (error) {
      console.log(error)
      ConnectionError(error)
    }
  }

}

export default AuthService

import Layout2 from '@/layout/Layout2'

export default [
  {
    path: '/magic-screen',
    component: Layout2,
    children: [
      {
        path: ':id',
        component: () => import('@/pages/magicScreen/List'),
        meta: {
          public: false // Allow access to only loggedin user.
        }
      },
      {
        path: '',
        component: () => import('@/pages/magicScreen/List'),
        meta: {
          public: false // Allow access to only loggedin user.
        }
      },
      {
        path: 'analysis/:id/:asinNumber',
        component: () => import('@/pages/magicScreen/analysis/Index'),
        meta: {
          public: false // Allow access to only loggedin user.
        }
      },
    ]
  }
]

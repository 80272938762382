// Application
export const APP_HOME_URL = '/'

// Authentication
export const AUTH_LOGIN_URL = '/api/auth/login/'
export const AUTH_REGISTER_URL = '/api/auth/register'
export const AUTH_RESET_PASSWORD_URL = '/api/auth/reset'

// Suppliers
export const SUPPLIER_URL = '/api/suppliers'
export const SUPPLIER_DETAIL_URL = '/api/suppliers/:id'
export const SUPPLIER_NOTE = '/api/suppliers/notes/:id'
export const SUPPLIER_CONTACT = '/api/suppliers/contacts/:id'
export const SUPPLIER_ADDRESS = '/api/suppliers/addresses/:id'
export const SUPPLIER_NAMES = '/api/suppliers/names'
export const SUPPLIERS_WITH_PRODUCTS = '/api/suppliers/with-products'
export const SUPPLIER_BRANDS = '/api/suppliers/brands?search=:query'
export const SUPPLIERS_WITH_PURCHASES = '/api/suppliers/with-purchases'

// User
export const USER_CHANGE_PASSWORD_URL = '/users/change'
export const USER_REFRESH_TOKEN_URL = '/users/refresh'
export const USER_SUBSCRIPTIONS_URL = '/users/subcriptions'

// ZipCode
export const ZIPCODE_URL = '/api/zipcode/:id'

// File Types
export const FILETYPE_URL = '/api/file/filetypes'
export const DOCUMENTTYPE_URL = '/api/file/documenttypes'
export const FILE_URL = '/api/file/:id'
export const DELETE_FILE_URL = '/api/file/supplier/:id'
export const FILE_DOWNLOAD_URL = '/api/file/download/:id'
export const FILE_NAMES_FROM_SUPPLIER = '/api/file/filenames/:id'

// Keepas
export const KEEPA_BREAK_EVEN_LIST_URL = '/api/keepa/break-even-list'
export const KEEPA_BREAK_EVEN_GET_URL = '/api/keepa/break-even/:id'
export const KEEPA_FILE_LIST_URL = '/api/file/keepa'
export const KEEPA_FILE_UPLOAD_URL = '/api/keepa/upload'
export const KEEPA_REPROCESS_URL = '/api/keepa/reprocess/:id'
export const KEEPA_REPROCESS_BY_SUPPLIER_FILE_ID_URL = '/api/keepa/reprocess/supplier-file-id/:id'

// Supplier Product
export const SUPPLIER_PRODUCT_FILE_LIST_URL = '/api/file/supplier-product'
export const SUPPLIER_PRODUCT_LOG_FILE_DOWNLOAD_URL = '/api/file/download-log/:id'
export const SUPPLIER_PRODUCT_FILE_UPLOAD_URL = '/api/file/supplier'
export const SUPPLIER_PRODUCT_LOG_UPCS_DOWNLOAD_URL = '/api/file/download-upcs/:id'
export const SUPPLIER_PRODUCT_LOG_UPCS_DIFF_DOWNLOAD_URL = '/api/file/download-diff-upcs/:id'
export const SUPPLIER_PRODUCT_FILE_DELETE_URL = '/api/file/supplier-product/:id'
export const SUPPLIER_PRODUCT_FILE_CONTENT_URL = '/api/productlist/file-content'

// AsinDataReview
export const ASIN_DATA_REVIEW_GET_ITEM = '/api/asindatareview/get/:supplierProductId/:asinNumber'
export const ASIN_UPC_MISMATCH_SET = '/api/asindatareview/asinupc-mismatch'
export const ASIN_DATA_REVIEW_SAVE = '/api/asindatareview/save'

// Magic Query
export const MAGIC_QUERY_LIST_URL = '/api/magicquery'
export const MAGIC_QUERY_CSV_URL = '/api/magicquery/csv'
export const MAGIC_QUERY_PRODUCT_DETAIL = '/api/magicquery/product/:supplierProductPriceId/:asinNumber'
export const MAGIC_QUERY_ANALYSIS_VIEW = '/api/magicquery/view/:asinNumber'
export const MAGIC_QUERY_UPDATE_PACK_SIZE = '/api/magicquery/packsizereview'
export const MAGIC_QUERY_UPDATE_STOCK_QUANTITY = '/api/magicquery/stock-quantity-review'


// Favorites
export const ASIN_UPC_FAVORITES = '/api/favorites/:id'

//Supplier Representatives
export const SUPPLIER_REPRESENTATIVE_ADD = 'api/representatives'
export const SUPPLIER_REPRESENTATIVE_UPDATE = 'api/representatives/:id'
export const SUPPLIER_REPRESENTATIVE_DELETE = 'api/representatives/:id/:supplierId'
export const SUPPLIER_REPRESENTATIVE_NOT_IN = 'api/representatives/not-in-supplier/:id'

// ProductAsinApprovals
export const PRODUCT_ASIN_APPROVAL_GET = 'api/productasinapproval/:asinNumber/:supplierProductBrId'
export const PRODUCT_ASIN_APPROVAL_SAVE = 'api/productasinapproval'

// Companies
export const COMPANY_LIST = '/api/companies/all'
export const COMPANY_WITH_PURCHASES = '/api/companies/with-purchases'

//NFEs
export const NFES_LIST_FILES = '/api/file/nfes'
export const NFE_FILE_UPLOAD_URL = '/api/file/nfes'
export const NFE_XML_READ_URL = '/api/nfes/:id'
export const NFE_XML_PUT_FILE_DATA = '/api/nfes/:id'
export const NFES_LIST = '/api/nfes/list'
export const NFES_NFE_ITEMS = '/api/nfes/list-items/:id'
export const NFES_NFE_SPLIT_ITEMS = '/api/nfes/split/:id'
export const NFES_FNSKUS = 'api/nfes/fnskus'
export const NFE_PUT_FNSKU_ID = '/api/nfes/item/:id'
export const NFES_NUMBERS = '/api/nfes/numbers/:id'

// Purchases
export const PURCHASE_LIST = '/api/purchase/list'
export const PURCHASE_ITEMS = '/api/purchase/list-items/:id'
export const PURCHASE_PUT = '/api/purchase/item/:id'
export const PURCHASE_DELETE = '/api/purchase/item-purchase/:id'
export const PURCHASE_ITEM_PUT = '/api/purchase/purchase-item/:id'
export const PURCHASE_ITEM_DELETE = '/api/purchase/purchase-item/:id'
export const PURCHASE_ADD_FNSKU = '/api/purchase/add-fnsku'

// Supplier Producst
export const SUPPLIER_PRODUCT_SEARCH = '/api/supplierproduct/search'
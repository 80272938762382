import FileService from '@/services/files'
export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    fileTypes: [],
    documentTypes: []
  },
  // -----------------------------------------------------------------
  getters: {
    fileTypes: state => {
      return state.fileTypes
    },
    documentTypes: state => {
      return state.documentTypes
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    setFileTypes: (state, data) => {
      state.fileTypes = data
    },
    setDocumentTypes: (state, data) => {
      state.documentTypes = data
    }
  },
  // -----------------------------------------------------------------
  actions: {
    async loadFileTypes ({ commit }) {
      const fileTypes = await FileService.FileTypes()
      const documentTypes = await FileService.DocumentTypes()
      commit('setFileTypes', fileTypes.data)
      commit('setDocumentTypes', documentTypes.data)
    }
  }
}

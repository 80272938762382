import TokenService from '@/services/shared/storage.service'
import AuthService from '@/services/auth'
import notify from '@/utils/notifications'
import md5 from 'js-md5'
import router from '@/router'
import { parseJwt } from '@/utils/jwt'
import Vue from 'vue'

import { AUTH_START_REQUEST, AUTH_SET_SUCCESS, AUTH_SET_ERROR, AUTH_RESET_STATUS, AUTH_LOGOUT_SUCCESSFUL, AUTH_SET_REFRESH_TASK }
  from '@/store/types/mutation.types'
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_REFRESH_TOKENS, AUTH_AUTO_REFRESH_TOKENS } from '@/store/types/action.types'
import { APP_HOME_URL } from '@/services/shared/endpoint.types'

const avatar = email => {
  return 'https://www.gravatar.com/avatar/' + md5(email.toLowerCase().trim())
}

const userAvatar = id => {
  return `/img/users/${id}.jpg`
}

const setError = (commit, error) => {
  commit(AUTH_SET_ERROR, error)
  notify(error.title, error.message, error.type)
  setTimeout(() => commit(AUTH_RESET_STATUS), 2000)
  return false
}

const setSuccess = (commit, data) => {
  commit(AUTH_SET_SUCCESS, data)
  setTimeout(() => commit(AUTH_RESET_STATUS), 2000)
  const redirectUrl = router.history.current.query.redirect || APP_HOME_URL
  router.push(redirectUrl)
  return true
}

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    status: '',
    accessToken: TokenService.getToken() || '',
    user: {},
    error: {},
    refreshTask: {}
  },
  // -----------------------------------------------------------------
  getters: {
    authStatus: state => state.status,
    isLoggedId: state => (!!state.accessToken),
    isConnecting: state => state.status === 'loading',
    user: state => state.user
  },
  // -----------------------------------------------------------------
  mutations: {
    [AUTH_START_REQUEST]: state => {
      state.status = 'loading'
    },
    [AUTH_SET_SUCCESS]: (state, data) => {
      state.status = 'success'
      if (data) {
        var decodedToken = parseJwt(data.token)
        const user = {
          firstName: decodedToken.firstName,
          email: decodedToken.email,
          avatar: avatar(decodedToken.email),
          role: decodedToken.role,
          userAvatar: userAvatar(decodedToken.id)
        }
        state.user = user
        state.accessToken = data.token
      }
    },
    [AUTH_SET_ERROR]: (state, { error }) => {
      state.status = 'error'
      state.error = error
    },
    [AUTH_RESET_STATUS]: state => {
      state.status = ''
      state.error = {}
    },
    [AUTH_LOGOUT_SUCCESSFUL]: state => {
      state.accessToken = ''
      state.user = {}
    },
    [AUTH_SET_REFRESH_TASK]: (state, task) => {
      state.refreshTask = task
    }
  },
  // -----------------------------------------------------------------
  actions: {
    async [AUTH_LOGIN] ({ commit, dispatch }, user) {
      commit(AUTH_START_REQUEST)
      try {
        const response = await AuthService.login(user)
        dispatch(AUTH_AUTO_REFRESH_TOKENS)
        setSuccess(commit, response)
      } catch (error) {
        setError(commit, error)
      }
    },

    [AUTH_LOGOUT] ({ commit, state }) {
      Vue.prototype.$socket.close()
      clearTimeout(state.refreshTask)
      AuthService.logout()
      commit(AUTH_LOGOUT_SUCCESSFUL)
      router.push('/login', () => {})
    },

    async [AUTH_REFRESH_TOKENS] ({ commit, dispatch }) {
      console.log('refreshing tokens')

      // Do whatever you need to do to exchange refresh token for access token
      var refreshToken = TokenService.getRefreshToken()

      // Se não tiver refresh_token, envia para o login
      if (!refreshToken) {
        dispatch(AUTH_LOGOUT)
        return false
      }

      const data = await AuthService.refreshToken()
      if (data) {
        commit(AUTH_SET_SUCCESS, data)
      } else {
        dispatch(AUTH_LOGOUT)
        return false
      }

      // Finally, call autoRefresh to set up the new timeout
      dispatch(AUTH_AUTO_REFRESH_TOKENS)
    },

    // Source: https://stackoverflow.com/questions/55747521/how-to-implement-auto-refresh-in-client-sidevue-js
    [AUTH_AUTO_REFRESH_TOKENS] ({ commit, dispatch }) {
      var token = TokenService.getToken()

      // Se não ouver token, faz logout
      if (!token) {
        dispatch(AUTH_LOGOUT)
        return false
      }

      try {
        var decodedToken = parseJwt(token)
        const exp = decodedToken.exp

        const now = Date.now() / 1000 // exp is represented in seconds since epoch
        let timeUntilRefresh = exp - now
        timeUntilRefresh -= 5 * 60 // Refresh 5 minutes before it expires
        // console.log(timeUntilRefresh);
        const refreshTask = setTimeout(() => dispatch(AUTH_REFRESH_TOKENS), timeUntilRefresh * 1000)

        // Handler para limpar o loop após o logout
        commit(AUTH_SET_REFRESH_TASK, refreshTask)
      } catch (error) {
        dispatch(AUTH_LOGOUT)
        return false
      }
    }
  }
}

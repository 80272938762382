/** VUEX module for Home **/

export const magicScreenArrayConst =
   [
    {id: 1, type: 'sp11', column: 'supplierName', heading: 'Fornecedor', sortable: false, visible: true},
    {id: 2, type: 'sku', column: 'sku', heading: 'SKU', sortable: true, visible: true},
    {id: 3, type: 'upc', column: 'upcNumber', heading: 'EAN', sortable: true, visible: true},
    {id: 4, type: 'asin', column: 'asinNumber', heading: 'ASIN', sortable: true, visible: true},
    
    {id: 5, type: 'str', column: 'supplierProductName', heading: 'Produto', sortable: true, visible: true},
    {id: 6, type: 'imgS', column: 'supplierProductImageUrl', heading: 'Imagem Prod.', sortable: false, visible: true},
    {id: 7, type: 'imgA', column: 'asinImageUrl', heading: 'Imagem Amz', sortable: false, visible: true},
    {id: 8, type: 'sp9', column: 'amazonName', heading: 'Produto Amz', sortable: true, visible: true},
    
    {id: 9, type: 'sp12', column: 'referenceSalesRank', heading: 'BSR', sortable: true, visible: true},
    {id: 10, type: 'sp1', column: 'referencePrice', heading: 'Preço Amz', sortable: true, visible: true},
    {id: 11, type: 'sp3', column: 'referralFeeValue', heading: 'Taxa Amz.', sortable: true, visible: true},
    {id: 12, type: 'dec', column: 'shippingCost', heading: 'Envio/FBA', sortable: true, visible: true},
    {id: 14, type: 'sp5', column: 'breakEven', heading: 'Break Even', sortable: true, visible: true},
    {id: 15, type: 'editInt', column: 'packSize', heading: 'Qtde', sortable: true, visible: true},
    {id: 41, type: 'editIntStk', column: 'stockQuantity', heading: 'Estoque Fornec.', sortable: true, visible: false},
    {id: 16, type: 'int', column: 'casePack', heading: 'Pcs/Caixa', sortable: true, visible: true},
    {id: 17, type: 'sp6', column: 'discountedUnitPrice', heading: 'Preço Unit.', sortable: true, visible: true},
    {id: 18, type: 'sp7', column: 'buyCost', heading: 'Preço Custo', sortable: true, visible: false},
    {id: 19, type: 'sp10', column: 'asinCost', heading: 'Custo Asin', sortable: true, visible: true},
    {id: 20, type: 'sp8', column: 'profitLoss', heading: 'Lucro', sortable: true, visible: true},
    {id: 21, type: 'fra', column: 'amazonOos90d', heading: 'OOs 90 d', sortable: true, visible: true},
    {id: 22, type: 'str', column: 'brandName', heading: 'Marca', sortable: true, visible: false},
    {id: 23, type: 'str', column: 'categoryName', heading: 'Categoria', sortable: true, visible: false},
    {id: 24, type: 'int', column: 'monthlyEstimatedSales', heading: 'Vendas Mensais', sortable: true, visible: false},
    {id: 25, type: 'tho', column: 'monthlyEstimatedRevenue', heading: 'Receita Mensal Est.', sortable: true, visible: false},
    {id: 26, type: 'int', column: 'fbaSellerCount', heading: 'Vendedores FBA', sortable: true, visible: false},
    {id: 27, type: 'fra', column: 'margin', heading: 'Margem %', sortable: true, visible: true},
    {id: 28, type: 'fra', column: 'roi', heading: 'ROI %', sortable: true, visible: true},
    {id: 29, type: 'int', column: 'reviewsCount', heading: 'Avaliações', sortable: true, visible: false},
    {id: 30, type: 'int', column: 'reviewsCount30d', heading: 'Avaliações (30 dias)', sortable: true, visible: false},
    {id: 31, type: 'dec', column: 'reviewsRating', heading: 'Avaliações', sortable: true, visible: false},
    {id: 32, type: 'dec', column: 'supplierShippingValue', heading: 'Envio', sortable: true, visible: false},
    {id: 33, type: 'dec', column: 'simplesValue', heading: 'Simples', sortable: true, visible: false},
    {id: 34, type: 'dec', column: 'difalValue', heading: 'Difal', sortable: true, visible: false},
    {id: 35, type: 'dec', column: 'prepValue', heading: 'Preparação', sortable: true, visible: false},
    {id: 36, type: 'fra', column: 'ipiPercentage', heading: 'IPI %', sortable: true, visible: false},
    {id: 37, type: 'dec', column: 'ipiValue', heading: 'IPI R$', sortable: true, visible: false},
    {id: 38, type: 'int', column: 'totalSellerCount', heading: 'Vendedores', sortable: true, visible: true},
    {id: 39, type: 'int', column: 'monthlyEstimatedSalesShare', heading: 'Share Vendas', sortable: true, visible: true},
    {id: 40, type: 'tho', column: 'monthlyEstimatedRevenueShare', heading: 'Share Receita', sortable: true, visible: true},
    {id: 41, type: 'dec', column: 'profit15', heading: 'Preço Margem Desejada', sortable: true, visible: true}
    
  ];


export const magicScreenCustomFiltersArrayConst =
   ['bsrBegin', 'bsrEnd', 'profitMin', 'profitMax', 'supplierId', 
    'categoryId', 'brandName', 'estimatedMonthlySalesMin', 'estimatedMonthlySalesMax', 
    'estimatedMonthlyRevenueMin', 'estimatedMonthlyRevenueMax',
    'marginMin', 'marginMax', 'roiMin', 'roiMax', 'amzOosMin', 'amzOosMax',
    'amzPriceMin', 'amzPriceMax', 'packSize', 'amazonSaleTypeId',
    'reviewsCountMin', 'reviewsCountMax',  'allFavorites', 'myFavorites',
    'alreadyBought',  'viewed', 'approvalStatus' ];


export const magicScreenCustomFiltersObjectConst =
   { 
    bsrBegin : 0, bsrEnd : 100000, profitMin : 3, profitMax : '', supplierId : 152,
    categoryId : '', brandName : '', estimatedMonthlySalesMin: '', estimatedMonthlySalesMax: '',
    estimatedMonthlyRevenueMin: '', estimatedMonthlyRevenueMax : '',
    marginMin: '', marginMax: '', roiMin: '', roiMax: '', amzOosMin : '', amzOosMax : '',
    amzPriceMin: '', amzPriceMax: '',  packSize: '', amazonSaleTypeId: 1,
    reviewsCountMin: '', reviewsCountMax: '', allFavorites: '', myFavorites: '',
    alreadyBought: '', viewed: '', approvalStatus: ''}


export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    magicScreenArray: magicScreenArrayConst,
    magicScreenCustomFiltersArray: magicScreenCustomFiltersArrayConst,
    magicScreenCustomFiltersObject: magicScreenCustomFiltersObjectConst
    // magicScreenSortableArray: magicScreenSortableArrayConst
  },
  // -----------------------------------------------------------------
  getters: {
    magicScreenArray: (state) => {
      return state.magicScreenArray
    },
    magicScreenSortableArray: (state) => {
      var arr = state.magicScreenArray.filter(q => q.sortable == true)
      return arr.map(q => q.column)
    },
    magicScreenArrayEnabled: (state) => {
      return state.magicScreenArray.filter(q => q.visible == true)
    },
    magicScreenColumns: (state, getters) => {
      return getters.magicScreenArrayEnabled.map(q => q.column)
    },
    magicScreenHeadings: (state, getters) => {
      var result = getters.magicScreenArrayEnabled.reduce(function(map, obj) {
        map[obj.column] = obj.heading;
        return map;
      }, {});
      return result;
    },
    magicScreenCustomFiltersArray: (state) => {
      return state.magicScreenCustomFiltersArray
    },
    magicScreenCustomFiltersObject: (state) => {
      return state.magicScreenCustomFiltersObject
    },
  },
  // -----------------------------------------------------------------
  mutations: {
    setMagicScreenArray: (state, data) => {
      state.magicScreenArray = data
    },
    setMagicScreenCustomFiltersObject: (state, data) => {
      state.magicScreenCustomFiltersObject = data
    },
  },
  // -----------------------------------------------------------------
  actions: {
    setMagicScreenArray ({ commit }, state) {
      commit('setMagicScreenArray', state)
    },
  }
}

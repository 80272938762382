<template>
  <sidenav :orientation="orientation" :class="curClasses">
    <!-- Brand demo (see src/demo.css) -->
    <div class="app-brand demo" v-if="orientation !== 'horizontal'">
      <span class="app-brand-logo demo bg-primary">
        <!-- Logo -->
        <app-logo height="50" width="50" fill="fill:#ffffff;"></app-logo>
        <!-- / Logo -->
      </span>
      <router-link
        to="/dashboard"
        class="app-brand-text demo sidenav-text font-weight-normal ml-2"
        >{{ appName }}</router-link
      >
    </div>
    <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>
    
    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">

      <sidenav-menu icon="ion ion-md-speedometer" :active="isMenuActive('/dashboard')" :open="isMenuOpen('/dashboard')">
        <template slot="link-text">Dashboards</template>
        <sidenav-router-link-disabled to="/dashboard/metas" :exact="true">Metas do Negócio</sidenav-router-link-disabled>
        <sidenav-router-link-disabled to="/dashboard/dados" :exact="true">Dashboard</sidenav-router-link-disabled>
      </sidenav-menu>

      <sidenav-menu icon="ion ion-md-cube" :active="isMenuActive('/suppliers')" :open="isMenuOpen('/suppliers')">
        <template slot="link-text">Fornecedores</template>
        <sidenav-router-link to="/suppliers/new" :exact="true">Adicionar</sidenav-router-link>
        <sidenav-router-link to="/suppliers" :exact="true">Cadastrados</sidenav-router-link>
        <sidenav-router-link to="/suppliers/price-lists" :exact="true">Listas de Preço</sidenav-router-link>
      </sidenav-menu>

      <sidenav-router-link v-if="!user.role.includes('VA')" icon="fas fa-magic" to="/magic-screen" :exact="true">Magic Screen</sidenav-router-link>

      <sidenav-menu v-if="!user.role.includes('VA')" icon="fas fa-shopping-cart" :active="isMenuActive('/orders')" :open="isMenuOpen('/orders')">
        <template slot="link-text">Compras</template>

        <sidenav-router-link-disabled v-if="!user.role.includes('VA')" icon="fas fa-shopping-cart" to="/carts" :exact="true">Carrinho</sidenav-router-link-disabled>
        <sidenav-router-link-disabled v-if="!user.role.includes('VA')" icon="fas fa-cart-plus" to="/orders" :exact="true">Pedidos</sidenav-router-link-disabled>
        <sidenav-router-link to="/orders/nfes-x" icon="ion ion-ios-checkbox-outline" :exact="true">Notas de Entrada</sidenav-router-link>
        <sidenav-router-link to="/orders/nfes" icon="ion ion-md-speedometer" :exact="true">Dashboard Compras</sidenav-router-link>
        <sidenav-router-link-disabled v-if="!user.role.includes('VA')" icon="fas fa-dollar-sign" to="/conciliacao" :exact="true">Conciliação Contábil</sidenav-router-link-disabled>

      </sidenav-menu>

      <sidenav-menu icon="ion ion-ios-hammer" :active="isMenuActive('/tools')" :open="isMenuOpen('/tools')">
        <template slot="link-text">Ferramentas</template>
        <!-- <sidenav-router-link to="/orders/purchases" :exact="true">Compras</sidenav-router-link> -->
        <sidenav-router-link-disabled to="#" :exact="true">Reviews Automáticos</sidenav-router-link-disabled>
        <sidenav-router-link-disabled to="#" :exact="true">Seller Spy</sidenav-router-link-disabled>
        <sidenav-router-link-disabled to="#" :exact="true">Listing Optimizer</sidenav-router-link-disabled>
        <sidenav-router-link-disabled to="#" :exact="true">Amz Keywords</sidenav-router-link-disabled>
        <sidenav-router-link-disabled to="#" :exact="true">Amz Ads Auto</sidenav-router-link-disabled>
      </sidenav-menu>

      <sidenav-menu icon="ion ion-md-browsers" :active="isMenuActive('/extensions')" :open="isMenuOpen('/extensions')">
        <template slot="link-text">Extensões</template>
        <!-- <sidenav-router-link to="/orders/purchases" :exact="true">Compras</sidenav-router-link> -->
        <sidenav-router-link-disabled to="#" :exact="true">AmzFy</sidenav-router-link-disabled>
        <sidenav-router-link-disabled to="#" :exact="true">Amz Import</sidenav-router-link-disabled>
      </sidenav-menu>

      <sidenav-menu icon="ion ion-ios-cash" :active="isMenuActive('/repricer')" :open="isMenuOpen('/repricer')">
        <template slot="link-text">Repricer</template>
        <!-- <sidenav-router-link to="/orders/purchases" :exact="true">Compras</sidenav-router-link> -->
        <sidenav-router-link-disabled to="#" :exact="true">Revendedor</sidenav-router-link-disabled>
        <sidenav-router-link-disabled to="#" :exact="true">Marca</sidenav-router-link-disabled>
      </sidenav-menu>

      <sidenav-menu icon="ion ion-md-umbrella" :active="isMenuActive('/marketplaces')" :open="isMenuOpen('/marketplaces')">
        <template slot="link-text">Marketplaces</template>
        <!-- <sidenav-router-link to="/orders/purchases" :exact="true">Compras</sidenav-router-link> -->
        <sidenav-router-link-disabled to="#" :exact="true">Seller</sidenav-router-link-disabled>
        <sidenav-router-link-disabled to="#" :exact="true">Brand</sidenav-router-link-disabled>
      </sidenav-menu>

      <sidenav-router-link-disabled v-if="!user.role.includes('VA')" icon="ion ion-md-finger-print" to="/investor" :exact="true">Investidores</sidenav-router-link-disabled>

      <!-- <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>
      <sidenav-router-link icon="ion ion-md-quote" to="/changelog" :exact="true">ChangeLog</sidenav-router-link> -->
      <sidenav-router-link-disabled to="#" :exact="false">Version 4.3.7 (2023-10-18)</sidenav-router-link-disabled>

      <!-- <sidenav-router-link icon="ion ion-md-cube" to="/suppliers" :exact="true">Suppliers</sidenav-router-link> -->
    </div>
  </sidenav>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider,
  SidenavRouterLinkDisabled
} from '@/vendor/libs/sidenav'

export default {
  data () {
    return {
      appName: process.env.VUE_APP_NAME
    }
  },
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider,
    SidenavRouterLinkDisabled,
    appLogo: () => import('@/components/shared/Logo')
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  computed: {
    ...mapGetters('AUTH', ['user']),
    curClasses () {
      let bg = this.layoutSidenavBg

      if (
        this.orientation === 'horizontal' &&
        (bg.indexOf(' sidenav-dark') !== -1 ||
          bg.indexOf(' sidenav-light') !== -1)
      ) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0')
      )
    }
  },

  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
      )
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    }
  }
}
</script>

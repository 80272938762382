<template>
  <b-navbar
    toggleable="lg"
    :variant="getLayoutNavbarBg()"
    class="layout-navbar align-items-lg-center container-p-x"
  >
    <!-- Sidenav toggle -->
    <b-navbar-nav
      class="align-items-lg-center mr-auto mr-lg-4"
      v-if="sidenavToggle"
    >
      <a
        class="nav-item nav-link px-0 ml-2 ml-lg-0"
        href="javascript:void(0)"
        @click="toggleSidenav"
      >
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <!-- Navbar toggle -->
    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

    <!-- <b-collapse is-nav id="app-layout-navbar">
      <b-navbar-nav class="align-items-lg-center">
        <b-nav-item href="#">Link 1</b-nav-item>
        <b-nav-item href="#">Link 2</b-nav-item>
      </b-navbar-nav>
    </b-collapse>-->

    <b-collapse is-nav id="app-layout-navbar">
      <!-- Divider -->
      <hr class="d-lg-none w-100 my-2" />

      <b-navbar-nav class="align-items-lg-center ml-auto">
        <b-nav-item-dropdown :right="!isRtlMode" class="demo-navbar-user">
          <template slot="button-content">
            <span
              class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
            >
              <img
                :src="user.userAvatar"
                alt
                class="d-block ui-w-30 rounded-circle"
              />
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">
                {{ user.firstName }}
              </span>
            </span>
          </template>

          <!-- <b-dd-item>
            <i class="ion ion-ios-person text-lightest"></i> &nbsp; Meus Dados
          </b-dd-item> -->

          <!-- <li role="presentation">
            <router-link
              to="/settings/account"
              class="dropdown-item"
              role="menuitem"
              target="_self"
              ><i class="ion ion-md-settings text-lightest"></i>
              &nbsp;Configurações</router-link
            >
          </li> -->

          <!-- <b-dd-divider /> -->

          <b-dd-item @click="AUTH_LOGOUT">
            <i class="ion ion-ios-log-out text-danger"></i> &nbsp; Sair
          </b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { AUTH_LOGOUT } from '@/store/types/action.types'
export default {
  name: 'app-layout-navbar',
  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('AUTH', ['isLoggedId', 'user'])
  },
  methods: {
    ...mapActions('AUTH', [AUTH_LOGOUT]),
    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },

    getLayoutNavbarBg () {
      return this.layoutNavbarBg
    }
  }
}
</script>


import TokenService from '@/services/shared/storage.service'
import store from '@/store/index'
import notify from '@/utils/notifications'

export default class ApiService {
  constructor (axiosService) {
    this.axios = axiosService
    this._401interceptor = null
  }

  setHeader () {
    this.axios.defaults.headers.common.Authorization = `Bearer ${TokenService.getToken()}`
    this.axios.defaults.headers.common['Content-Type'] = 'application/json'
  }

  removeHeader () {
    this.axios.defaults.headers.common = {}
  }

  get (resource) {
    return this.axios.get(resource)
  }

  get (resource, data) {
    return this.axios.get(resource, { params: data })
  }

  post (resource, data) {
    return this.axios.post(resource, data)
  }

  put (resource, data) {
    return this.axios.put(resource, data)
  }

  delete (resource) {
    return this.axios.delete(resource)
  }

  customRequest (data) {
    return this.axios(data)
  }

  download (resource, data) {
    return this.axios.get(resource, { params: data }, { responseType: 'blob' })
  }

  mount401Interceptor () {
    this._401interceptor = this.axios.interceptors.response.use(
      response => {
        return response
      },
      async error => {
        console.log(error)
        if (error.request.status === 401) {
          notify('Atenção', 'Sessão expirada')
          store.dispatch('AUTH/AUTH_LOGOUT')
          throw error
        }

        // If error was not 401 just reject as is
        throw error
      }
    )
  }

  unmount401Interceptor () {
    // Eject the interceptor
    this.axios.interceptors.response.eject(this._401interceptor)
  }
}

// Sources:
// https://morioh.com/p/4bb19aa8ea3e
// https://github.com/aiyazkhorajia/vue-boilerplate

import { authAxios } from '@/services/shared/axios.service'
import ApiService from '@/services/apis/api.service'

class Auth extends ApiService {
  constructor () {
    super(authAxios)
  }
}

export const AuthApiService = new Auth()

import Vue from 'vue'

export default (title, text, type = 'warn', group = 'main') => {
  Vue.notify({
    group: group,
    type: type,
    title: title,
    text: text
  })
}

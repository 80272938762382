import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// import SecureLS from 'secure-ls'

import AUTH from '@/store/modules/auth/'
import APP from '@/store/modules/app/'
import SOCKET from '@/store/modules/socket'
import SUPPLIER from '@/store/modules/suppliers'
import TABLE from '@/store/modules/tables'
// var ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    AUTH,
    APP,
    SOCKET,
    SUPPLIER,
    TABLE
  },
  plugins: [
    createPersistedState({
      key: 'data',
      paths: ['AUTH.user', 'TABLE']
      // storage: {
      //   getItem: key => ls.get(key),
      //   setItem: (key, value) => ls.set(key, value),
      //   removeItem: key => ls.remove(key)
      // }
    })
  ],
  strict: false
})

// Best Practices:
// Source: https://docs.vuestorefront.io/guide/vuex/vuex-conventions.html#getters

// Authentication
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_REFRESH_TOKENS = 'AUTH_REFRESH_TOKENS'
export const AUTH_AUTO_REFRESH_TOKENS = 'AUTH_AUTO_REFRESH_TOKENS'

// App
export const APP_SET_NOME_ATIVO_SELECIONADO = 'APP_SET_NOME_ATIVO_SELECIONADO'
export const APP_PRE_LOAD = 'APP_PRE_LOAD'

// OPC
export const OPC_LOAD_ATIVOS = 'OPC_LOAD_ATIVOS'
export const OPC_LOAD_OPCOES = 'OPC_LOAD_OPCOES'

// COT
export const COT_LOAD_COTACOES_OPCOES_ATIVO = 'COT_LOAD_COTACOES_OPCOES_ATIVO'
export const COT_LOAD_COTACOES_OPCOES_ATIVO_DATA = 'COT_LOAD_COTACOES_OPCOES_ATIVO_DATA'
export const COT_LOAD_COTACOES_OPCAO = 'COT_LOAD_COTACOES_OPCAO'
export const COT_LOAD_DATA_ULTIMO_PREGAO = 'COT_LOAD_DATA_ULTIMO_PREGAO'
export const COT_SET_DATA_PREGAO = 'COT_SET_DATA_PREGAO'
